import React, { useEffect } from "react";
import Progress from "../../../Components/Progress";
import { skillInfo } from "../../../Data/skillInfo";
import { scrollTo } from "../../../tools/scroll";
import VideoPlayer2 from "../../../Components/VideoPlayer2";
import IceButtonLong from "../../../Components/Shared/IceButtonLong/IceButtonLong";

const About = () => {
  useEffect(() => {
    scrollTo(10, 10);
  }, []);

  return (
    <section className="my-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mt-5">
            <h2 className="text-center ">
              <div className="text-center paintedtext buttons abouuut">
                <h2>About Us</h2>
                <h2>About Us</h2>
              </div>
            </h2>
          </div>

          <div className="col-12 skills">
            <div className="row align-items-center about-section">
              <div className="col-12  col-md-6 position-relative ">
                  <VideoPlayer2 className="videoabout-section"/>
               
              </div>
              <div className="col-md-6">
                <p className="about-text videoabout-sectiontext fontweight">
                  Rúllan Painting Services Ehf has been painting in Iceland from
                  2007 to the present day, and now it's time to paint your
                  house. Rúllan takes on projects for individuals, housing
                  associations, and businesses. We offer roof painting, exterior
                  and interior painting, varnishing of windows and doors, and
                  much more.
                </p>
                <ul className="fontweight">
                  <li>
                    Roof Painting: Quality roof painting services to protect and
                    beautify your home.
                  </li>
                  <li>
                    Interior and Exterior Painting: Comprehensive painting
                    services for both the inside and outside of your house.
                  </li>
                  <li>
                    Window and Door Varnishing: Professional varnishing to
                    maintain and enhance your windows and doors.
                  </li>
                  <li>
                    Custom Painting Projects: Tailored painting solutions to
                    meet your unique needs.
                  </li>
                </ul>

                <p className="about-text fontweight">
                  We are always open and ready to provide you with the best
                  offer for your painting needs. Contact us today to get
                  started!
                </p>
                <div className="col-md-12 text-center">
       <a href="/contact">
       <IceButtonLong   label="Request a Free Quote Today!" />
</a>
       </div>
              </div>
            </div>
          </div>
          {skillInfo.map((each) => (
            <Progress key={each.name} perchantage={each} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
