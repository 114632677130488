import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const Gallery = ({ projectList }) => {
  const [limitedProjectList, setLimitedProjectList] = useState([]);
  const [show, setShow] = useState(false);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [leftButtonAnimation, setLeftButtonAnimation] = useState("");
  const [rightButtonAnimation, setRightButtonAnimation] = useState("");

  useEffect(() => {
    const limitedList = projectList.slice(0, 10);
    setLimitedProjectList(limitedList);
  }, [projectList]);

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    if (index >= 0 && index < limitedProjectList.length) {
      setCurrentProjectIndex(index);
      setShow(true);
    }
  };

  const handleNext = () => {
    setCurrentProjectIndex(
      (prevIndex) => (prevIndex + 1) % limitedProjectList.length
    );
    triggerAnimation("right");
  };

  const handlePrev = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? limitedProjectList.length - 1 : prevIndex - 1
    );
    triggerAnimation("left");
  };

  const triggerAnimation = (direction) => {
    if (direction === "left") {
      setLeftButtonAnimation("wobble-left");
      setTimeout(() => setLeftButtonAnimation(""), 500);
    } else {
      setRightButtonAnimation("wobble-right");
      setTimeout(() => setRightButtonAnimation(""), 500);
    }
  };

  const reconcileDOM = () => {
    const actualElements = document.querySelectorAll(".collection-images");
    if (actualElements.length > limitedProjectList.length) {
      for (let i = limitedProjectList.length; i < actualElements.length; i++) {
        actualElements[i].remove();
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(reconcileDOM, 1000);
    return () => clearInterval(interval);
  }, [limitedProjectList]);

  if (!limitedProjectList || limitedProjectList.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {limitedProjectList.map((project, index) => (
        <img
          key={index}
          src={project.image}
          alt={project.projectName || "Project"}
          onClick={() => handleShow(index)}
          className="collection-images zfront p-1 hover:greyscale"
          data-index={index}
        />
      ))}

      {limitedProjectList.length > 0 && (
        <Modal className="modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {limitedProjectList[currentProjectIndex]?.projectName ||
                "Project"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a
              href={limitedProjectList[currentProjectIndex]?.imageHref || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="modal-image"
                src={limitedProjectList[currentProjectIndex]?.image || ""}
                alt={
                  limitedProjectList[currentProjectIndex]?.projectName ||
                  "Project"
                }
              />
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={`left-button ${leftButtonAnimation}`}
              variant="secondary"
              onClick={handlePrev}
            >
              Previous
            </Button>
            <Button
              className={`right-button ${rightButtonAnimation}`}
              variant="secondary"
              onClick={handleNext}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Gallery;
