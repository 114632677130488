import React from "react";
import { Card } from "react-bootstrap";
import StarRatings from "react-star-ratings";

const TestimonialCard = ({ testimonialInfo }) => {
  const { name, post, review, photo, rating } = testimonialInfo;

  return (
    <div className="justify-content-center mb-5 mx-3">
      <Card border="light" className=" shadow reviewhome" style={{ width: "18rem", marginBottom:"2rem", }}>
        <div className="row mt-3">
          <div className="col-4 d-flex justify-content-center align-items-center">
            <img
              style={{ borderRadius: "50%", width: "50px", height: "50px" }}
              src={photo || "/profil-no.png"}
              alt={name}
            />
          </div>
          <div className="col-8">
            <h6>{name}</h6>
            <small>{post}</small>
          </div>
        </div>
        <Card.Body>
          <Card.Text className="custom-review-card">{review}</Card.Text>
          <div className="d-flex justify-content-start">
            <StarRatings
              rating={Number(rating)}
              starDimension="20px"
              starSpacing="4px"
              starRatedColor="#FFAC0C"
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TestimonialCard;
