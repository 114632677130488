import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { MdAddIcCall } from "react-icons/md";
import { MdOutgoingMail } from "react-icons/md";
import VideoPlayer from "../../../Components/VideoPlayer";
import IceButtonNormal from "../../../Components/Shared/IceButtonNormal/IceButtonNormal";

const TopBanner = () => {
  return (
    <section className="mt-5 ">
      <div className=" spaceservices mobilelock"></div>
      <div className="container ">
        <div className="row align-items-center justify-content-center ">
          <div className="col-md-6">
            <div className="fontweight container">
              <h1 className="text-dangen fontweight1">
                Rúllan málningarþjónusta ehf
              </h1>

              <p className="mt-5  mobilelock">
                <span>
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    color="green"
                    icon={faCheck}
                  />{" "}
                  Since 2007
                </span>
                <span className="ml-md-4 d-md-inline d-block">
                  <FontAwesomeIcon
                    style={{ marginRight: "8px", marginLeft: "8px" }}
                    color="green"
                    icon={faCheck}
                  />
                  Quality Workmanship
                </span>
                <span className="ml-md-4 d-md-inline d-block">
                  <FontAwesomeIcon
                    style={{ marginRight: "8px", marginLeft: "8px" }}
                    color="green"
                    icon={faCheck}
                  />
                  Residential & Commercial
                </span>
              </p>
              <p className="mt-4 ">
                Við höfum málað á Íslandi frá árinu 2007 þar til dagsins í dag
                og nú er komið að húsinu þínu. Við hjá Rúllan málningu erum
                faglærðir með Sveinspróf málarar með marga ára reynslu af öllum
                þáttum málningarvinnu, sandspörtlun, endurmálun, þakmálun,
                úti-/innimálun, silan, pallaáburð, Málum glugga, epoxy viðhald,
                utanhússviðgerðum o.s.frv. Bjóðum einnig uppá góð tilboð fyrir
                fyrirtæki s.s. heilsuræktarstöðvar, verslanir, hótel, veitinga-
                og skemmtistaði. Markmið okkar er að skila af okkur vel unnum
                verkum á skömmum tíma. Leggjum mikla áherslu á: fagmennsku,
                þjónustu, vönduð vinnubrögð og dugnað. Bjóðum uppá frábær
                tilboð. Fyrir pantanir og/eða nánari upplýsingar getið þið haft
                samband Við gerum þér tilboð að kostnaðarlausu.
              </p>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <a
                  aria-label="Email Rullan Malningar"
                  href="mailto:rullanmalningar@gmail.com"
                >
                  <MdOutgoingMail size={36} className=" text-dangen" />
                </a>

                <a aria-label="phone Rullan Malningar" href="tel:+3546974563">
                  <MdAddIcCall size={36} className=" text-dangen" />
                </a>
              </div>
              <div className="col-12 mt-5 headerbutton">
                <a href="/contact">
                  <IceButtonNormal label="Get In Touch!" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-5">
            <div className="text-center ">
              <VideoPlayer />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
