import React, { useRef } from "react";
import RiveOrchestrator from "../../Rivetoggle";

const ThemeToggle = ({ isDarkTheme, toggleTheme }) => {
  const lastClickTimeRef = useRef(0);

  const handleClick = (event) => {
    const currentTime = Date.now();
    if (currentTime - lastClickTimeRef.current < 2100) {
      return; 
    }
    lastClickTimeRef.current = currentTime;
  
    toggleTheme(event);
  };
  
  return (
    <div 
      onClick={handleClick} 
      onTouchStart={handleClick} 
      className="rive-toggle"
    >
      <RiveOrchestrator />
    </div>
  );
  
};

export default ThemeToggle;
