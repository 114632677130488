/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright} from "@fortawesome/free-solid-svg-icons";
import { faCcVisa, faCcMastercard } from "@fortawesome/free-brands-svg-icons";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Testimonial from "../../../Pages/Home/Testimonial/Testimonial";

const Footer = () => {
  return (
    <section>
      <div className="mb-5">
        <Testimonial />
      </div>
      <div className="footercolors p-5 mt-5">
        <div className="container">
          <div className="row">
            <div className=" col-md-4">
              <p > kt 611221-1340 (vask.143352)</p>
              <br></br>
              <p>
                <FontAwesomeIcon className="mr-1" icon={faCopyright} />
                 
                 2024 © All Copyright Reserved 
              </p>
              <p>Rúllan málningarþjónusta ehf</p>
             
            </div>
         
            <div className="col-md-2">
              <p className=" mb-4 mt-md-0 mt-4">Company</p>
              <p>
                <a href="/about" className="text-white">
                  About
                </a>
              </p>
              <p>
                <a href="/projects" className="text-white">
                  Latest Project
                </a>
              </p>
              <p>
                <a href="/terms" className="text-white">
                  Terms & Condition
                </a>
              </p>
              <p>
                <a href="/privacy" className="text-white">
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="col-md-2">
              <p className="mb-4 mt-md-0 mt-4">Quick Links</p>
              <p>
                <a href="/services" className="text-white">
                  Services
                </a>
              </p>
              <p>
                <a href="/contact" className="text-white">
                  Contact
                </a>
              </p>
              <p>
                <a href="/review" className="text-white">
                  Review Buisness
                </a>
              </p>
            </div>
            <div className="col-md-4 ">
              <a href="/about" className="text-white">
                <p className="mb-4 mt-md-0 mt-4">Get in Touch</p>
              </a>
              <a href="mailto:rullanmalningar@gmail.com" className="text-white d-flex align-items-center">
          <FaEnvelope size={30} />
          <p className="contact-text  ms-2">rullanmalningar@gmail.com</p>
        </a>
        <a href="tel:+3546974563" className=" text-white d-flex align-items-center">
          <FaPhone size={30} />
          <p className="contact-text ms-2">+354 697 4563</p>
        </a>
      
           
              <div>
                <a
                  href="https://www.facebook.com/search/top?q=r%C3%BAllan%20m%C3%A1lningar%C3%BEj%C3%B3nusta"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faFacebookSquare}
                  />
                </a>
                <a
                  href="https://www.instagram.com/rullanmalningar"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faInstagramSquare}
                  />
                </a>

                <a href="#" aria-label="Visa">
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faCcVisa}
                  />
                </a>
                <a href="#" aria-label="Mastercard">
                  <FontAwesomeIcon
                    className="m-1 text-white"
                    size="2x"
                    icon={faCcMastercard}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
