import React, { Suspense, useState, useEffect, useRef } from "react";
import About from "../About/About";
import Services from "../PaintingServices/PaintingServices";
import TopBanner from "../TopBanner/TopBanner";
import Projects from "../Projects/Projects";

const Contactus = React.lazy(() => import("../Contactus/Contactus"));

const Home = () => {
  const [loadContactus, setLoadContactus] = useState(false);
  const contactusRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === contactusRef.current) {
              setLoadContactus(true);
              observer.unobserve(contactusRef.current);
            }
          }
        });
      },
      {
        rootMargin: "200px",
      }
    );

    if (contactusRef.current) observer.observe(contactusRef.current);

    return () => {
      if (contactusRef.current) observer.unobserve(contactusRef.current);
    };
  }, []);

  return (
    <div>
      <TopBanner />
      <div className="sectiontopdiv"></div>
      <div>
        <About />
      </div>
      <div>
        <Services />
      </div>
      <div>
        <Projects />
      </div>

      <div ref={contactusRef}>
        {loadContactus && (
          <Suspense fallback={<div>Loading Contact...</div>}>
            <Contactus />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default Home;
