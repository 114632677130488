import React, { useEffect, useState } from "react";
import ServiceCard from "../../../Components/ServiceCard/ServiceCard";
import Loader from "../../../Components/Shared/Loader/Loader";
import { getRullanServices } from "../../../Services/UserServices";
import { scrollTo } from "../../../tools/scroll";

const PaintingServices = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    scrollTo(90, 20);
    getRullanServices().then((data) => {
      setServices(data);
    });
  }, []);

  return (
    <section>
   
      <div >   
      <h2 className="text-center headerservices">
        <br></br>    <br></br>
              <div className="text-center paintedtext mt-5">
                <h2>Our Services</h2>
                <h2>Our Services</h2>
              </div>
            </h2>

        <div className="row justify-content-center">
          {services.length === 0 ? (
            <div className="mb-5">
              <Loader />
            </div>
          ) : (
            services.map((each, index) => (
              <ServiceCard key={each._id || index} serviceInfo={each} />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default PaintingServices;
