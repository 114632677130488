import { useEffect, useState } from "react";
import { storage } from "../firebase.config"; 

const defaultProjectDetails = {
  projectLocation: "Reykjavik, Iceland",
  imageHref: "/contact",
};

const useProjectList = () => {
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const galleryRef = storage.ref("gallery/");
        const result = await galleryRef.listAll(); 

        const projects = await Promise.all(
          result.items.map(async (itemRef) => {
            const imageURL = await itemRef.getDownloadURL(); 
            const fileName = itemRef.name.split('.')[0]; 

            return {
              image: imageURL,
              projectName: fileName, 
              ...defaultProjectDetails,
            };
          })
        );

        setProjectList(projects);
      } catch (error) {
        console.error("Error fetching images from Firebase Storage:", error.message);
        console.error("Error details:", error);
      }
    };

    fetchImages();
  }, []);

  return projectList;
};

export default useProjectList;
