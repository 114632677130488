import React from "react";
import styles from "./IceButton.module.css";

const IceButton = ({ label }) => {
  return (
    <div className={styles.btn}>
      <div className={styles.cta}>{label}</div>
      <div className={styles.arrow}>
        <div className={styles.arrowTop}></div>
        <div className={styles.arrowBot}></div>
      </div>


      <div className={styles.dropsStart}>
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="200px"
          height="80px"
          viewBox="0 0 200 80"
          style={{ enableBackground: "new 0 0 200 80" }}
          xmlSpace="preserve"
        >
          <path
            className={styles.st0}
            d="M44,0c0,0-6,0-6,5.4c0,0.1,0,0.2,0,0.2s0.1,5.4-5.7,5.4l0,0C26.5,11,27,5.6,27,5.6s0,0,0-0.2
      C27,0,20.8,0,20.8,0"
          />
          <path
            className={styles.st0}
            d="M68,0c0,0-6,0-6,5.4c0,0.1,0,0.2,0,0.2s0.1,5.4-5.7,5.4l0,0C50.5,11,51,5.6,51,5.6s0,0,0-0.2
      C51,0,44.8,0,44.8,0"
          />
          <path
            className={styles.st0}
            d="M158,0c0,0-6,0-6,5.4c0,0.1,0,0.2,0,0.2s0.1,5.4-5.7,5.4l0,0c-5.8,0-5.3-5.4-5.3-5.4s0,0,0-0.2
      c0-5.4-6.2-5.4-6.2-5.4"
          />
        </svg>
      </div>

      <div className={styles.drops}>
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="200px"
          height="80px"
          viewBox="0 0 200 80"
          style={{ enableBackground: "new 0 0 200 80" }}
          xmlSpace="preserve"
        >
          <path
            className={styles.st0}
            d="M44,0c0,0-6,0-6,5.4s0,11.2,0,11.2s0.1,5.4-5.7,5.4l0,0c-5.8,0-5.3-5.4-5.3-5.4s0-5.8,0-11.2
      S20.8,0,20.8,0"
          />
          <path
            className={styles.st0}
            d="M67.9,0c0,0-5.9,0-5.9,5.4S62,37,62,37s-0.1,6-5.9,6l0,0c-5.8,0-6.1-6-6.1-6s0-26.2,0-31.6
      S44.3,0,44.3,0"
          />
          <path
            className={styles.st0}
            d="M122.1,0c0,0-6.1,0-6.1,5.4s0,11.2,0,11.2s0.2,5.4-5.6,5.4l0,0c-5.8,0-6.5-5.4-6.5-5.4s0-5.8,0-11.2
      C104,0,98.4,0,98.4,0"
          />
          <path
            className={styles.st0}
            d="M158.1,0c0,0-6.1,0-6.1,5.4s0,24.8,0,24.8s0.3,6.8-5.5,6.8l0,0c-5.8,0-5.5-6.8-5.5-6.8s0-19.4,0-24.8
      S134.9,0,134.9,0"
          />
          <path
            className={styles.st0}
            d="M180.3,0c0,0-6.3,0-6.3,5.4s0,2.4,0,2.4s0.7,6.2-5.1,6.2l0,0c-5.8,0-5.9-6.2-5.9-6.2s0,3.1,0-2.4
      c0-5.4-6-5.4-6-5.4"
          />
        </svg>
      </div>
    
    </div>
  );
};

export default IceButton;
