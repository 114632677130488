import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const Progress = ({ perchantage }) => {
  const { desc, perchant, name, pathColor } = perchantage;

  return (
    <div className="col-md-6 p-3">
      <div className="row">
        <div className="col-4">
          <CircularProgressbar
            value={perchant}
            text={`${Math.round(perchant)}%`} 
            styles={buildStyles({
              pathColor: pathColor,
              textColor: pathColor,
              transition: "stroke-dashoffset 0.7s ease 0s",
            })}
          />
        </div>
        <div className="col-8">
           <h3>{name}</h3>
          <p className="text-secondary fontweight">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default Progress;
