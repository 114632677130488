export const skillInfo = [
  {
    perchant: 100,
    name: "Exceptional Quality",
    pathColor: "#299129",
    desc: "With over a decade of experience, Rúllan málningarþjónusta delivers top-notch painting services, ensuring a flawless finish that enhances the beauty and longevity of your property.",
  },
  {
    perchant: 100,
    name: "Reliable and Trustworthy",
    pathColor: "#3993D1",
    desc: "Our commitment to reliability and trustworthiness means you can count on us to complete your painting projects on time and to the highest standards, giving you peace of mind and exceptional results.",
  },
];
