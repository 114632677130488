import { useRive, Layout, Alignment } from "@rive-app/react-canvas";

export const RiveToggle = () => {
  const { RiveComponent } = useRive({
    src: "/them_chenager.riv",

    stateMachines: "Button_Animation",
    layout: new Layout({
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return <RiveComponent />;
};

export default function App() {
  return (
    <div className="RiveContainer">
      <RiveToggle />
    </div>
  );
}
