import React, { useEffect, useState } from "react";
import ProjectCard from "../../../Components/ProjectCard/ProjectCard";
import useProjectList from "../../../Data/projectData";
import { scrollTo } from "../../../tools/scroll";
import Gallery from "../../../Components/Gallery";
import IceButton from "../../../Components/Shared/IceButton/IceButton";


const workerPath = new URL("../../../worker.js", import.meta.url);

const Projects = () => {
  const [processedProjectList, setProcessedProjectList] = useState([]);
  const [loading, setLoading] = useState(true);

  const projectList = useProjectList();

  useEffect(() => {
    scrollTo(10, 10);
  
    const worker = new Worker(workerPath);
  
    worker.postMessage(projectList);
  
    worker.onmessage = (event) => {
      const data = event.data;
      if (Array.isArray(data)) {
        setProcessedProjectList(data.slice(0, 10)); 
      } else {
        console.error("Worker returned data that is not an array:", data);
        setProcessedProjectList([]);
      }
      setLoading(false);
    };
  
    worker.onerror = (error) => {
      console.error("Worker error:", error);
      setLoading(false);
    };
  
    return () => {
      worker.terminate();
    };
  }, [projectList]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="mt-5">
      <div className="container">
        <div className="row justify-content-center">
          <IceButton label="Our Projects" />
          <div className="col-12 mt-3">
            <h2 className="mb-5 text-center mt-3">
              Some of Our Latest Projects
            </h2>
          </div>
          <Gallery projectList={processedProjectList}>
            {processedProjectList.length > 0 ? (
              processedProjectList.map((each, index) => (
                <ProjectCard key={index} projectInfo={each} />
              ))
            ) : (
              <p>No projects available.</p>
            )}
          </Gallery>
        </div>
      </div>
    </section>
  );
};

export default Projects;
