import { useRef, useEffect } from "react";

const VideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && videoElement) {
          videoElement.src = videoElement.dataset.src; 
          observer.unobserve(videoElement); 
        }
      },
      { threshold: 0.1 }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement); 
      }
    };
  }, []);

  return (
    <div className="videoheader" style={{ position: "relative", height: "430px", width: "100%" }}>
      <video
        ref={videoRef}
        className="HeaderImage"
        style={{ height: "100%", width: "100%", objectFit: "cover", paddingLeft:"0", }}
        data-src="/header.mp4"
        alt="Professional Painting Services"
        autoPlay
        loop
        muted
        preload="metadata"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
