import firebase from "firebase/app";
import "firebase/storage"; 


const firebaseConfig = {
  apiKey: "AIzaSyBDqYbJZBAi7pY8YpJkAjvTccfaNqm-m1s",
  authDomain: "rullan-ehf-database.firebaseapp.com",
  projectId: "rullan-ehf-database",
  storageBucket: "rullan-ehf-database.appspot.com",
  messagingSenderId: "279948706164",
  appId: "1:279948706164:web:cfb4a0ad84c034f196ec21",
  measurementId: "G-KGX94NTYMD",
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}


const storage = firebase.storage();

export { storage };
export default firebaseConfig; 
